'use client'
import { ReactNode } from 'react'
import { getClientEnvVariable } from '@/util/environment-variables'
import { FlagProvider } from '@unleash/nextjs/client'

export default function UnleashProvider({ children }: { children: ReactNode }) {
  const config = {
    url: 'https://us.app.unleash-hosted.com/usgg0005/api/frontend',
    clientKey: getClientEnvVariable('NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN'),
    appName: 'app',
  }

  //@ts-ignore
  return <FlagProvider config={config}> {children}</FlagProvider>
}
